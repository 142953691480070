export const postEmptyCardRelationshipsData = async (t) => {
  try {
    // If local dev, set local data
    if (process.env.POWERUP_NAME.includes("LOCAL")) {
      await t.set("organization", "shared", "localCardRelationships", {});
    } else {
      // Otherwise, post to Netlify function
      try {
        const organization = (await t.organization("id")) || {};
        const response = await fetch(
          "/.netlify/functions/cardRelationshipsApi",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.SECRET_API_KEY,
            },
            body: JSON.stringify({
              organizationId: organization.id,
              cardRelationshipsData: {},
            }),
          }
        );
        const result = await response.json();

        if (!response.ok) {
          console.error(
            "Response was not ok for postEmptyCardRelationshipsData:",
            result
          );
        }
      } catch (error) {
        console.error("Error in postEmptyCardRelationshipsData:", error);
      }
    }
  } catch (error) {
    console.error("Failed postEmptyCardRelationshipsData:", error);
  }
};
