import getCardRelationshipsData from "../services/api/getCardRelationshipsData";
import { postEmptyCardRelationshipsData } from "../services/api/postEmptyCardRelationshipsData";

export async function migrateOldDataToNetlify({ t, orgId }) {
  console.log(`Attempting migration for orgId=${orgId}`);

  try {
    // 1) Fetch the existing migration list
    const listResponse = await fetch(
      "/.netlify/functions/cardRelationshipsApi?organizationId=cardRelationshipsMigrationList",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.SECRET_API_KEY,
        },
      }
    );

    const listText = await listResponse.json();
    let migrationList = JSON.parse(listText);

    // 2) If not in the list, read old Trello data
    const oldTrelloData = await t.get(
      "organization",
      "shared",
      "cardRelationships"
    );
    if (!oldTrelloData || Object.keys(oldTrelloData).length === 0) {
      // No old data to migrate, just mark migration done
      migrationList[orgId] = {
        migrationDate: new Date().toISOString(),
        trelloDataCleanup: true, // no data to remove
      };
      await postEmptyCardRelationshipsData(t);
      await postMigrationList(migrationList);
      return {};
    }

    // 3) Save old data to Netlify
    const saveResp = await fetch("/.netlify/functions/cardRelationshipsApi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.SECRET_API_KEY,
      },
      body: JSON.stringify({
        organizationId: orgId,
        cardRelationshipsData: oldTrelloData,
      }),
    });
    if (!saveResp.ok) {
      throw new Error("Failed to save old Trello data to Netlify");
    }

    // 4) Update the migration list with date + no cleanup
    migrationList[orgId] = {
      migrationDate: new Date().toISOString(),
      trelloDataCleanup: false,
    };
    await postMigrationList(migrationList);

    // 5) Re-fetch from netlify to confirm
    console.log(`Migration was successful for orgId=${orgId}`);
    const data = await getCardRelationshipsData(t);

    return data;
  } catch (error) {
    console.error("Migration error for org", orgId, error);
    return {};
  }
}

async function postMigrationList(migrationList) {
  await fetch("/.netlify/functions/cardRelationshipsApi", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.SECRET_API_KEY,
    },
    body: JSON.stringify({
      organizationId: "cardRelationshipsMigrationList",
      cardRelationshipsData: migrationList,
    }),
  });
}
