import { migrateOldDataToNetlify } from "../../utils/migrateOldDataToNetlify";

const getCardRelationshipsData = async (t) => {
  try {
    // Ensure `t` is not null or undefined
    if (!t) {
      throw new Error("Trello iframe context (t) is not initialized.");
    }

    if (process.env.POWERUP_NAME.includes("LOCAL")) {
      const localCardRelationships = await t.get(
        "organization",
        "shared",
        "localCardRelationships"
      );

      return localCardRelationships;
    }

    const org = await t.organization("id");
    const response = await fetch(
      `/.netlify/functions/cardRelationshipsApi?organizationId=${org.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.SECRET_API_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Response was not ok fetching card relationships data: ${response.status}`
      );
    }

    const dataString = await response.json();

    // If no data exists, then run the database migration
    if (response.ok && !dataString) {
      const data = await migrateOldDataToNetlify({
        t,
        orgId: org.id,
      });
      return data;
    }

    const data = JSON.parse(dataString);

    return data;
  } catch (error) {
    console.error("Error fetching card relationships data:", error);
    return null;
  }
};

export default getCardRelationshipsData;
